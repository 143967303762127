import { ForceRedirect } from 'components/ForceRedirect';
import { Page } from 'components/_shared/Page';
import { Section } from 'components/_shared/Section';

import { Typeform } from 'components/_shared/Typeform';
import React, { useEffect } from 'react';

const isBrowser = () => typeof window !== 'undefined';

export default function LandingMobile() {
  const params = isBrowser() ? new URLSearchParams(window.location.search) : '';
  const parsed = Object.fromEntries(params);
  const utm_source = parsed?.utm_source;
  const utm_medium = parsed?.utm_medium;
  const utm_campaign = parsed?.utm_campaign;
  const utm_term = parsed?.utm_term;
  const utm_content = parsed?.utm_content;
  const hidden_fields = {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content
  };
  useEffect(() => {
    if (isBrowser()) {
      window.location.href = 'https://app.devrev.ai/signup';
    }
  }, []);
  return <Page />;
}
